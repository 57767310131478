import Vue from 'vue'
import LeagueEditToast from '@/components/administrator/SportsPanel/LeagueEditToast.vue'

let LeagueEditToastConstructor = Vue.extend(LeagueEditToast) // 构造函数
let instance // 实例对象
let seed = 1 // 计数

const LeagueEditToastDialog = (options = {}) => {
    if (typeof options === 'string') {
        options = {
            leagueId: null,
            type: 'save'
        }
    }
    let id = `sportsscoretoast_${seed++}`
    instance = new LeagueEditToastConstructor({
        data: options
    })
    instance.id = id
    instance.vm = instance.$mount()
    document.body.appendChild(instance.vm.$el)
    instance.vm.visible = true
    instance.dom = instance.vm.$el
    instance.dom.style.zIndex = 999 + seed
    return instance.vm
}

export default LeagueEditToastDialog